import { isEmpty } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAdditivesWOK } from "../../action/neworder";
const AdditivesWOKNewOrder = ({ index, closeAdditivesWOK }) => {
    const settings = useSelector(state => state.settings.init.categories);
    const goodsChek = useSelector(state => state.good.list);
    const neworders = useSelector(state => state.neworders);

    const [AdditivesWOK, setAdditivesWOK] = useState('')

    const dispatch = useDispatch();

    const AdditivesCategories = [
        {id: settings.mainWok, title: 'Лапша ВОК'}, 
        {id: settings.meatWok, title: 'Мясо в ВОК'}, 
        {id: settings.toppingWok, title: 'Топпинги в ВОК'}, 
        {id: settings.souceWok, title: 'Соус в ВОК'}
    ]

    const checkRegionPrice = (field, good, regionPrice) => {
        if(regionPrice.active) {
            switch(regionPrice.condition) {
                case('equal'): {
                    if(regionPrice[field]) {
                        return regionPrice[field];
                    } else {
                        if(good[field])
                            return good[field];
                        return null;
                    }
                }
                case('plus'): {
                    if(good[field]) {
                        if(regionPrice[field]) {
                            if(regionPrice.persent)                        
                                    return Math.round((good[field] + (good[field] / 100 * regionPrice[field])));
                                return Math.round((good[field] + regionPrice[field]).toFixed(0));
                        } else {
                            return good[field];
                        }
                    } else {
                        return null;
                    }
                }
                case('minus'): {
                    if(good[field]) {
                        if(regionPrice[field]) {
                            if(regionPrice.persent)
                                return Math.round((good[field] - (good[field] / 100 * regionPrice[field])).toFixed(0));
                            return Math.round((good[field] - regionPrice[field]).toFixed(0));
                        } else {
                            return good[field];
                        }
                    } else {
                        return null;
                    }
                }
                case('multiply'): {
                    if(good[field]) {
                        if(regionPrice[field]) {
                            return Math.round((good[field] * regionPrice[field]).toFixed(0));
                        } else {
                            return good[field];
                        }
                    } else {
                        return null;
                    }
                }                
                    default: {
                    if(good[field]) {
                        return good[field]; 
                        } else {
                            return null;
                        }
                    }
            }
        } else {
            return good[field];
        }
    }

    const filterGoods = useCallback(() => {
        const categoryIds = AdditivesCategories.map(category => category.id);
        setAdditivesWOK(
            goodsChek.filter((item) => categoryIds.includes(item.category.id))
            .sort((a, b) => {
                if(a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if(a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
            })
            .filter((item) => { //проверка на доступность позиции в городе
                const disabledCityIds = item.disabledCities ? item.disabledCities.split(',') : [];
                return !disabledCityIds.some((id) => parseInt(id) === (neworders.point.city.id ? neworders.point.city.id : neworders.city.id));
            })
            .map(item => {
                const regionPrice = item.regionprices.find(region => 
                    region.cities.some(c => parseInt(c.id) === (neworders.point.city.id ? neworders.point.city.id : neworders.city.id))
                );
    
                return {
                    ...item,
                    price: regionPrice ? checkRegionPrice('price', item, regionPrice) : item.price,
                    oldPrice: regionPrice ? checkRegionPrice('oldPrice', item, regionPrice) : item.oldPrice,
                }                  
            })
        );
    }, [goodsChek, AdditivesCategories, neworders.point, neworders.city]);

    useEffect(() => {
        filterGoods();
    }, [filterGoods]);

        return(
            <div className="popup_additives"
            onMouseDown={((e) => e.preventDefault())}
            onBlur={closeAdditivesWOK}
            >
                {isEmpty(AdditivesCategories) ? <li>Добавок нет</li> : AdditivesCategories.map((category) => (
                    <div key={category.id} className="popup_additives_category">
                        <span>{category.title}</span>
                        <div className="popup_additives_buttons_div">
                            {isEmpty(AdditivesWOK) ? <li>Добавок нет</li> : AdditivesWOK.map((item) => {
                                if (!item || !item.category) {
                                    return null;
                                }
                                 
                                return item.category.id === category.id &&
                                item.category.id === category.id &&
                                <button 
                                key={item.id} 
                                className={`popup_additives_button ${(!neworders.point || neworders.point.stopList.some((el) => el.value === item.id)) ? 'unactived' : ''}`} 
                                onMouseDown={((e) => e.preventDefault())}
                                onBlur={closeAdditivesWOK}
                                onClick={(() => {
                                    if(neworders.point && !neworders.point.stopList.some((el) => el.value === item.id))
                                        dispatch(addAdditivesWOK(index, item, settings, item.id))
                                })}
                                >                                    
                                    {item.hot && <div className="popup_additives_hot_circle"></div>}
                                    <span className="neworder_good_button_text"> {item.name} </span>
                                    <span className="neworder_good_button_text" style={{ fontWeight: '600', fontSize: '0.8rem' }}> {`${!neworders.isAggPrice ? item.price : item.oldPrice} ₸`} </span>
                                </button> 
                            })}
                        </div>
                    </div>
                ))}
                    
            </div>
        );
    
}
export default AdditivesWOKNewOrder